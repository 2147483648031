
import { defineComponent, watch } from "vue";
import { useUiStore } from "@/store/ui";
import useSlides from "../../../../composables/useSlides";

export default defineComponent({
    name: "SliderProgress",
    components: {},
    setup() {
        const { slides, currentSlide } = useSlides();
        const uiStore = useUiStore();

        watch(
            () => slides.value.length,
            (newN) => {
                // protection against bug where you end up with 7/5
                // when going back after checkout
                // slide in url is (eg) 7, but because of the prefilled options, slides are hidden
                if (uiStore.currentSlide >= newN) {
                    uiStore.currentSlide = newN - 1;
                }
            }
        );

        return {
            currentSlide,
            slides,
        };
    },
});
