import {
    OptionsState,
    PersonalInfoState,
    ExamState,
    DateMethod,
    UiState,
} from "@/types";
import { getOptionBySlug, getOptionGroupBySlug } from "@/utils/base-products";
import {
    OPTION_SLUGS,
    SLUGS,
    DATE_METHOD_SELF_BOOKING_SLUG,
    GROUP_OBFUSCATION,
} from "@/data/constants";

const getOptions = (optionsState: OptionsState) => {
    const set = [];
    for (const slug in optionsState.selection) {
        const optionGroup = getOptionGroupBySlug(
            optionsState.baseProducts,
            slug,
            optionsState.selection
        );
        if (optionGroup) {
            const option = optionsState.selection[slug];
            const item = {
                option_group_id: optionGroup.id,
                option_id: option.id,
            };
            set.push(item);
        }
    }
    if (!optionsState.selection[SLUGS.COURSE_TYPES]) {
        // there is no course type, therefor we add offline as default
        const optionGroup = getOptionGroupBySlug(
            optionsState.baseProducts,
            SLUGS.COURSE_TYPES,
            optionsState.selection
        );
        const option = getOptionBySlug(
            optionsState.baseProducts,
            OPTION_SLUGS.OFFLINE
        );
        if (optionGroup && option) {
            const item = {
                option_group_id: optionGroup.id,
                option_id: option.id,
            };
            set.push(item);
        }
    }
    return set;
};

const getSelfBookingDetails = (personalInfoState: PersonalInfoState) => {
    return {
        cbr_location_id: personalInfoState.selfBooking.location,
        own_exam_date: personalInfoState.selfBooking.date,
        own_exam_time: personalInfoState.selfBooking.time,
    };
};

const getPeriodIds = (
    examState: ExamState,
    optionsState: OptionsState
): number[] => {
    if (isSelfBooking(optionsState)) {
        const selfBookingDateMethod = getSelfBookingDateMethod(examState);
        if (selfBookingDateMethod) {
            const id = selfBookingDateMethod.id as number;
            return [id];
        } else {
            return [];
        }
    } else {
        if (examState.selection.dates.method) {
            const dateMethodId = examState.selection.dates.method.id as number;
            switch (examState.selection.dates.method.slug) {
                case "month":
                case "vacation":
                    return examState.selection.dates.details.map((d) => d.id);
                case "asap":
                case "specific-dates":
                    return [dateMethodId];
                default:
                    return [];
            }
        } else {
            return [];
        }
    }
};

const getPeriodType = (examState: ExamState, optionsState: OptionsState) => {
    if (isSelfBooking(optionsState)) {
        return DATE_METHOD_SELF_BOOKING_SLUG;
    } else {
        return examState.selection.dates.method
            ? examState.selection.dates.method.type
            : null;
    }
};

const getExamDetails = (examState: ExamState, optionsState: OptionsState) => {
    return {
        cbr_location_ids: examState.selection.cbrLocations.map((l) => l.id),
        location_ids: examState.selection.locations.map((l) => l.id),
        period_ids: getPeriodIds(examState, optionsState),
        specific_dates: examState.selection.dates.specificDates,
        period_type: getPeriodType(examState, optionsState),
    };
};

export const transformPayload = (
    optionsState: OptionsState,
    personalInfoState: PersonalInfoState,
    examState: ExamState,
    uiStore: UiState
) => {
    return {
        personal: {
            ...personalInfoState.form,
            ...getSelfBookingDetails(personalInfoState),
            ...getExamDetails(examState, optionsState),
            reExamID:
                personalInfoState.reExamID.length > 0
                    ? personalInfoState.reExamID
                    : null,
        },
        options: getOptions(optionsState),
        [GROUP_OBFUSCATION]: uiStore.group,
    };
};

// helper functions
const isSelfBooking = (optionsState: OptionsState) => {
    return (
        optionsState.selection[SLUGS.EXAM_TYPES]?.slug ===
        OPTION_SLUGS.SELF_BOOKING
    );
};

const getSelfBookingDateMethod = (examState: ExamState) => {
    return examState.dates.methods.find((dateMethod: DateMethod) => {
        return dateMethod.slug === DATE_METHOD_SELF_BOOKING_SLUG;
    });
};
