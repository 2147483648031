import { SlideKey, Slug } from "@/types";

export const MAX_OPTIONS: Record<string, number> = {
    MONTHS: 3,
    VACATIONS: 4,
    DATES: 100,
};

export const SLIDE: Record<SlideKey, string> = {
    CBR_LOCATIONS: "SlideCbrLocations",
    COURSE_TYPES: "SlideCourseTypes",
    DATES: "SlideDates",
    LOCATIONS: "SlideLocations",
    OVERVIEW: "SlideOverview",
    ONLINE_PACKAGES: "SlideOnlinePackages",
    PERSONAL_INFO: "SlidePersonalInfo",
    EXAM_TYPES: "SlideExamTypes",
    PRODUCT_CATEGORIES: "SlideProductCategories",
    SELF_BOOKING: "SlideSelfBooking",
};

export const OPTION_SLUGS: Record<string, Slug> = {
    ONLINE: "Online",
    OFFLINE: "Fysiek",
    REGULAR: "Regulier",
    EXTENDED: "Verlengd",
    SELF_BOOKING: "Eigen",
    DAYS7: "7-dagen",
    DAYS14: "14-dagen",
    DAYS2: "48-uur",
    MONTHS1: "1-maand",
    CAR: "Autotheorie",
    MOTOR: "Motortheorie",
    MOPED: "Scootertheorie",
};

// key is the slug of the option
export const ICONS_NEW: Record<string, string> = {
    DATES: "calendar-range",
    LOCATIONS: "location-check",
    CBR_LOCATIONS: "location-check",
    SELF_BOOKING: "location-check",
    EXAM_TYPES: "graduation-cap",
    ONLINE_PACKAGES: "box-check",
    fallback: "bars",
    // icon custom for a choice
    [OPTION_SLUGS.OFFLINE]: "screen-users",
    [OPTION_SLUGS.ONLINE]: "laptop-mobile",
    [OPTION_SLUGS.CAR]: "car",
    [OPTION_SLUGS.MOTOR]: "motorcycle",
    [OPTION_SLUGS.MOPED]: "moped",
};

export const SLUGS: Record<SlideKey, Slug> = {
    CBR_LOCATIONS: "cbr_locations",
    COURSE_TYPES: "course-type",
    DATES: "",
    EXAM_TYPES: "exam-type",
    LOCATIONS: "locations",
    ONLINE_PACKAGES: "online-packages",
    OVERVIEW: "",
    PERSONAL_INFO: "",
    PRODUCT_CATEGORIES: "product-category",
    SELF_BOOKING: "",
};

export const DATE_METHOD_SELF_BOOKING_SLUG = "self";

export const GOOGLE_ADWORDS_CONVERSION_ID = "AW-935562830";

export const GROUP_OBFUSCATION = "period";

export const CURRENT_TEST_GROUPS = ["a1", "b1"];
