import { defineStore } from "pinia";
import { UiState } from "@/types";
import { CURRENT_TEST_GROUPS } from "@/data/constants";

export const useUiStore = defineStore("ui", {
    state: () => {
        const state: UiState = {
            displayHelpdeskPopup: false,
            currentSlide: 0,
            group:
                Math.random() > 0.5
                    ? CURRENT_TEST_GROUPS[0]
                    : CURRENT_TEST_GROUPS[1],
        };
        return state;
    },
    getters: {
        isTestUser: (state: UiState) => {
            return state.group === CURRENT_TEST_GROUPS[1];
        },
    },
    actions: {
        nextSlide() {
            this.currentSlide++;
        },
        previousSlide() {
            this.currentSlide--;
        },
        jumpToSlide(s: number) {
            this.currentSlide = s;
        },
    },
});
