import { ICONS_NEW, OPTION_SLUGS, SLIDE, SLUGS } from "../data/constants";
import { measureSlider } from "../utils/measure";
import {
    CbrLocation,
    DateDetail,
    DateMethod,
    Location,
    Option,
    SelfBookingForm,
    SlideKey,
    Month,
    Vacation,
    DateString,
} from "@/types";
import { computed } from "vue";
import { getSlideNames } from "./get-slide-names";
import { useOptionsStore } from "../store/options";
import { getOptionGroupBySlug } from "../utils/base-products";
import { useUiStore } from "@/store/ui";
import { usePersonalInfoStore } from "@/store/personal-info";
import { useExamStore } from "@/store/exam";
import { nl } from "date-fns/locale";
import { format } from "date-fns";
import i18n from "@/i18n";

export default function useSlides() {
    const uiStore = useUiStore();
    const optionsStore = useOptionsStore();
    const personalInfoStore = usePersonalInfoStore();
    const examStore = useExamStore();
    const { t } = i18n.global;

    const currentSlide = computed(() => uiStore.currentSlide);

    const slideNameToSlideKey = (slideName: string): SlideKey | null => {
        for (const key in SLIDE) {
            const slideKey = key as SlideKey;
            if (SLIDE[slideKey] === slideName) {
                return slideKey;
            }
        }
        return null;
    };

    const summaryItems = computed(() => {
        const tbd = "-";
        const ignore = [SLIDE.OVERVIEW, SLIDE.PERSONAL_INFO];

        const slideNames = getSlideNames(
            optionsStore,
            personalInfoStore,
            false
        );

        const slidesWithoutOverview = [...slideNames].filter(
            (slide: string) => !ignore.includes(slide)
        );

        const getExamTypeLabel = (slideKey: SlideKey) => {
            const optionGroupSlug = SLUGS[slideKey];
            const selection = optionsStore.selection[optionGroupSlug];
            if (selection && selection.slug === OPTION_SLUGS.SELF_BOOKING) {
                const date = personalInfoStore.selfBooking.date;
                const time = personalInfoStore.selfBooking.time;
                let formattedDate = "";
                if (date && date.length > 0) {
                    formattedDate = format(new Date(date), "dd MMM yyyy", {
                        locale: nl,
                    });
                }
                if (formattedDate.length > 0 && time.length > 0) {
                    return t("slides.SlideOverview.selfBookingLabel.dateTime", {
                        date: formattedDate,
                        time,
                    });
                } else if (formattedDate.length > 0) {
                    return t("slides.SlideOverview.selfBookingLabel.date", {
                        date: formattedDate,
                    });
                } else {
                    return t("slides.SlideOverview.selfBookingLabel.none");
                }
            } else {
                if (optionGroupSlug in optionsStore.selection) {
                    return optionsStore.selection[optionGroupSlug].name;
                } else {
                    return tbd;
                }
            }
        };

        const getLabel = (slideKey: SlideKey): string => {
            switch (slideKey) {
                case "EXAM_TYPES":
                    return getExamTypeLabel(slideKey);
                case "CBR_LOCATIONS":
                    return examStore.selection.cbrLocations
                        .map((cbrLocation) => cbrLocation.address)
                        .join(", ");
                case "SELF_BOOKING":
                    if (personalInfoStore.selfBooking.location) {
                        const location = examStore.cbrLocations.find(
                            (cbrLocation) => {
                                return (
                                    cbrLocation.id ===
                                    personalInfoStore.selfBooking.location
                                );
                            }
                        );
                        return location
                            ? "Eigen examen: " + location.address
                            : tbd;
                    } else {
                        return tbd;
                    }
                case "LOCATIONS":
                    return examStore.selection.locations
                        .map((location) => location.name)
                        .join(", ");
                case "DATES":
                    if (examStore.selection.dates.method) {
                        switch (examStore.selection.dates.method.slug) {
                            case "asap":
                                return "Zo snel mogelijk";
                            case "vacation":
                            case "month":
                                return [...examStore.selection.dates.details]
                                    .sort((a, b) => {
                                        return a.order - b.order;
                                    })
                                    .map((m) => {
                                        const detail = m as Month | Vacation;
                                        return detail.name;
                                    })
                                    .join(", ");
                            case "specific-dates":
                                return [
                                    ...examStore.selection.dates.specificDates,
                                ]
                                    .sort((a, b) => {
                                        const aT = new Date(a).getTime();
                                        const bT = new Date(b).getTime();
                                        return aT - bT;
                                    })
                                    .map((d) => {
                                        const detail = d as DateString;
                                        return format(
                                            new Date(detail),
                                            "dd MMM yyyy",
                                            {
                                                locale: nl,
                                            }
                                        );
                                    })
                                    .join(", ");
                            default:
                                return "-";
                        }
                    } else {
                        return tbd;
                    }
                default:
                    // eslint-disable-next-line no-case-declarations
                    const optionGroupSlug = SLUGS[slideKey];
                    if (optionGroupSlug in optionsStore.selection) {
                        return optionsStore.selection[optionGroupSlug].name;
                    } else {
                        return tbd;
                    }
            }
        };

        const getIcon = (slideKey: SlideKey) => {
            const fallback = ICONS_NEW.fallback;
            const specificKeys: SlideKey[] = [
                "PRODUCT_CATEGORIES",
                "COURSE_TYPES",
            ];
            if (specificKeys.includes(slideKey)) {
                const optionGroupSlug = SLUGS[slideKey];
                if (optionGroupSlug in optionsStore.selection) {
                    return ICONS_NEW[
                        optionsStore.selection[optionGroupSlug].slug
                    ];
                } else {
                    return fallback;
                }
            } else {
                return ICONS_NEW[slideKey];
            }
        };

        return slidesWithoutOverview.map((slideName) => {
            const slideKey = slideNameToSlideKey(slideName);
            const label = slideKey ? getLabel(slideKey) : "";
            const icon = slideKey ? getIcon(slideKey) : "";
            return {
                icon,
                label,
                component: slideName,
            };
        });
    });

    const allSlides = computed(() => {
        return [
            {
                name: SLIDE.PRODUCT_CATEGORIES,
                rule: () => optionsStore.selection[SLUGS.PRODUCT_CATEGORIES],
                data: {
                    optionGroup: getOptionGroupBySlug(
                        optionsStore.baseProducts,
                        SLUGS.PRODUCT_CATEGORIES,
                        optionsStore.selection
                    ),
                },
                selection: optionsStore.selection[SLUGS.PRODUCT_CATEGORIES],
                atSelect: (option: Option) => {
                    optionsStore.selectOption(option);
                },
            },
            {
                name: SLIDE.EXAM_TYPES,
                rule: () => optionsStore.selection[SLUGS.EXAM_TYPES],
                data: {
                    optionGroup: getOptionGroupBySlug(
                        optionsStore.baseProducts,
                        SLUGS.EXAM_TYPES,
                        optionsStore.selection
                    ),
                },
                selection: optionsStore.selection[SLUGS.EXAM_TYPES],
                atSelect: (option: Option) => optionsStore.selectOption(option),
            },
            {
                name: SLIDE.COURSE_TYPES,
                rule: () => optionsStore.selection[SLUGS.COURSE_TYPES],
                data: {
                    optionGroup: getOptionGroupBySlug(
                        optionsStore.baseProducts,
                        SLUGS.COURSE_TYPES,
                        optionsStore.selection
                    ),
                },
                selection: optionsStore.selection[SLUGS.COURSE_TYPES],
                atSelect: (option: Option) => optionsStore.selectOption(option),
            },
            {
                name: SLIDE.CBR_LOCATIONS,
                rule: () => examStore.selection.cbrLocations.length > 0,
                data: {
                    items: examStore.cbrLocations,
                },
                selection: {
                    items: examStore.selection.cbrLocations,
                },
                atSelect: (cbrLocation: CbrLocation) =>
                    examStore.toggleCbrLocation(cbrLocation),
            },
            {
                name: SLIDE.LOCATIONS,
                rule: () => examStore.selection.locations.length > 0,
                data: {
                    items: examStore.locations,
                },
                selection: {
                    items: examStore.selection.locations,
                },
                atSelect: (location: Location) =>
                    examStore.toggleLocation(location),
            },
            {
                name: SLIDE.ONLINE_PACKAGES,
                rule: () => optionsStore.selection[SLUGS.ONLINE_PACKAGES],
                data: {
                    optionGroup: getOptionGroupBySlug(
                        optionsStore.baseProducts,
                        SLUGS.ONLINE_PACKAGES,
                        optionsStore.selection
                    ),
                },
                selection: optionsStore.selection[SLUGS.ONLINE_PACKAGES],
                atSelect: (option: Option) => optionsStore.selectOption(option),
            },
            {
                name: SLIDE.SELF_BOOKING,
                rule: () => personalInfoStore.selfBooking.location !== null,
                data: {
                    locations: examStore.cbrLocations,
                },
                selection: {},
                atSelect: (selfBookingForm: SelfBookingForm) =>
                    (personalInfoStore.selfBooking = selfBookingForm),
            },
            {
                name: SLIDE.DATES,
                rule: () => {
                    return (
                        examStore.selection.dates.method &&
                        (examStore.selection.dates.method.slug === "asap" ||
                            examStore.selection.dates.details.length > 0 ||
                            examStore.selection.dates.specificDates.length > 0)
                    );
                },
                data: {
                    dateMethods: examStore.dates.methods.filter(
                        (d: DateMethod) => {
                            const slugs = optionsStore.getSelectedOptions.map(
                                (o) => o.slug
                            );
                            const slugsToHide = d.hideFor.filter((slug) =>
                                slugs.includes(slug)
                            );
                            return d.display && slugsToHide.length === 0;
                        }
                    ),
                    dateDetailItems: {
                        month: examStore.dates.months,
                        vacation: examStore.dates.vacations,
                        "specific-dates": [],
                    },
                },
                selection: {
                    dateMethod: examStore.selection.dates.method,
                },
                atSelect: (dateMethod: DateMethod) => {
                    examStore.selectDateMethod(dateMethod);
                    if (dateMethod.slug !== "asap") {
                        // wait for render of Panel in Dates.vue
                        setTimeout(() => {
                            measureSlider(currentSlide.value);

                            setTimeout(() => {
                                const anchor = document.querySelector(
                                    "#date-picker-details"
                                );
                                if (anchor) {
                                    const scrollElement =
                                        document.querySelector("main");
                                    if (scrollElement) {
                                        const y =
                                            scrollElement.scrollTop +
                                            anchor.getBoundingClientRect().y -
                                            140;

                                        scrollElement.scrollTo({
                                            top: y,
                                            behavior: "smooth",
                                        });
                                    }
                                }
                            });
                        });
                    }
                },
                atSelectDetail: (dateDetails: DateDetail[]) => {
                    examStore.selectDateDetails(dateDetails);
                },
            },
            {
                name: SLIDE.PERSONAL_INFO,
                data: {},
                rule: () => personalInfoStore.formValid,
            },
            {
                name: SLIDE.OVERVIEW,
                rule: () => personalInfoStore.consent,
                data: {
                    summaryItems: summaryItems.value,
                    form: personalInfoStore.form,
                    consent: personalInfoStore.consent,
                },
                atSelect: (consent: boolean) => {
                    personalInfoStore.consent = consent;
                },
            },
        ];
    });

    const slideNames = computed(() =>
        getSlideNames(optionsStore, personalInfoStore, true)
    );

    const slides = computed(() => {
        return slideNames.value.map((name: string) =>
            allSlides.value.find((slide) => slide.name === name)
        );
    });

    const currentSlideName = computed(
        () => slideNames.value[currentSlide.value]
    );

    const passesRule = computed(() => {
        const slide = slides.value[currentSlide.value];
        if (slide) {
            return slide.rule();
        } else {
            return true;
        }
    });

    return {
        allSlides,
        slides,
        currentSlide,
        currentSlideName,
        passesRule,
        slideNames,
    };
}
